/* custom.scss */
$primary: #113eb8;
$secondary: #adc3ff;
$success: #3e8d63;
$info: #7854e4;
$warning: #b8c924;
$danger: #ff8793;
$light: #f8f9fa;
$dark: #343a40;
/* end custom.scss */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
@import '~bootstrap/scss/bootstrap.scss';
